import React from "react";

const AnnouncementsImageWithText = ({
  title,
  description,
  btnRedirectText,
  imgURL,
  href,
  showTint = true,
  heading, // Default heading value
}) => {
  return (
    <a href={href || "#"} className={`cs_img_with_txt_sec ${showTint ? 'show-tint' : ''}`} style={{ textDecoration: "none", color: "inherit" }}>
      <img loading="lazy" src={imgURL || "/images/home_3/blog_1.jpeg"} alt={title} />
      <div className="cs_img_with_txt_sec_info">
        <h4>{title}</h4>
        <p title={description}>{description}</p>
        {btnRedirectText && (
          <div className="cs_img_with_txt_sec_btn">
            <button
              style={{
                color: "white",
                paddingLeft: 25,
                paddingRight: 30,
                fontSize: 14,
              }}
              className="cs_btn cs_style_1"
            >
              <span>{btnRedirectText || "Contact us"} </span>
            </button>
          </div>
        )}
      </div>
      <h4 style={{ textAlign: "center", marginTop: "10px" }}>{heading}</h4>
    </a>
  );
};

export default AnnouncementsImageWithText;

