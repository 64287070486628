import React from "react";
import parser from "html-react-parser";
import Spacing from "../../Spacing";
import "../../../sass/shortcode/announcement.scss"

export default function BannerSectionStyle12({
  bgUrl,
  title,
  subTitle,
  content,
  subTitleTans = "",
  center,
}) {
  return (
    <div className="container">
      <div
        className={`cs_banner cs_style_4 cs_bg_filed overflow-hidden ${
          center ? "text-center" : ""
        }`}
        style={{
          backgroundImage: `url(${bgUrl})`,
           borderRadius: "20px"
        }}
      >
        <h3 className="cs_banner_title cs_heading_color cs_fs_48">
          {parser(title)}
        </h3>
        {subTitle && (
          <h4 className="cs_banner_subtitle cs_heading_color cs_fs_20 m-0">
            {parser(subTitle)}
          </h4>
          
        )}
        <Spacing lg="600" md="10" />
                {content && (
          <p className="cs_banner_content cs_white_color cs_fs_32 m-0">
            {parser(content)}
          </p>
        )}

        {subTitleTans && (
          <p className="cs_banner_subtitle cs_white_color cs_fs_20 m-0">
            {subTitleTans}
          </p>
        )}
      </div>
    </div>
  );
}
