// import i18n from "../../../i18n/i18n";

export const NewsandAnnouncements = () => {

  const blogInfoGrapics = [
    {
      title: "",
      description: "",
      btnRedirectText: "",
      imgURL: "/images/news_Announcement/Luna-kidney-clickable700x700.jpg",
      heading: "LunaKIDNEY",
      href: "/luna-kidney",
    },
    // {
    //   title: "",
    //   description: "",
    //   btnRedirectText: "",
    //   imgURL: "/images/kidney_educations/healthy-kidneys/info02.png",
    //   heading: "LunaKIDNEY",
    // //   href: "/News-Announcements-1",
    // },
    // {
    //   title: "",
    //   description: "",
    //   btnRedirectText: "",
    //   imgURL: "/images/kidney_educations/healthy-kidneys/info03.png",
    //   heading: "LunaKIDNEY"
    // },
    // {
    //   title: "",
    //   description: "",
    //   btnRedirectText: "",
    //   imgURL: "/images/kidney_educations/healthy-kidneys/info04.png",
    //   heading: "LunaKIDNEY"
    // },
  ]
  return {
    blogInfoGrapics
  };
};
