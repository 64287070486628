import React from "react";
// import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
// import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
// import TeamSectionStyle5 from "../Section/TeamSection/TeamSectionStyle5";
import Section from "../Section";
import "../../sass/shortcode/findcenter.scss"
import { pageTitle } from "../../helpers/PageTitle";
import Searchcenter from "./Findcentersearch";
// const teamData = [
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis Center",
//     designation: "New Delhi, Delhi",
//     address: "A5 / 51, Ground floor, near, VIP store Gully, near Metro pillar 311, Block 1, New Delhi, Delhi, 110015i",
//     contact: "+91 9111606162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     googleLink: "https://maps.app.goo.gl/SjTDE2sQjDsniUMj6",
//     timings: "Mon-Sat : 08:00 AM - 08:00 PM",
//     patientSatisfactionScore: "94"
//   },
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis CenterA",
//     designation: "Egmore, Chennai, Tamil Nadu.",
//     address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
//     contact: "+91 9116 06162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     timings: "Mon-Sat : 08:00 AM - 09:00 PM",
//     patientSatisfactionScore: "97"
//   },
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis CenterB",
//     designation: "Egmore, Chennai, Tamil Nadu.",
//     address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
//     contact: "+91 9116 06162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     timings: "Mon-Sat : 08:30 AM - 09:00 PM",
//     patientSatisfactionScore: "95"
//   },
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis CenterC",
//     designation: "Egmore, Chennai, Tamil Nadu.",
//     address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
//     contact: "+91 9116 06162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     timings: "Mon-Sat : 09:00 AM - 09:30 PM",
//     patientSatisfactionScore: "99"
//   },
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis CenterD",
//     designation: "Egmore, Chennai, Tamil Nadu.",
//     address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
//     contact: "+91 9116 06162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     timings: "Mon-Sat : 08:00 AM - 08:00 PM",
//     patientSatisfactionScore: "97"
//   },
//   {
//     imgUrl: "/images/doctors/doctor_1.jpeg",
//     department: "Directions",
//     name: "KidneyNeeds Dialysis CenterE",
//     designation: "Egmore, Chennai, Tamil Nadu.",
//     address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
//     contact: "+91 9116 06162",
//     email: "support@kidneyneeds.com",
//     services: ["Hemodialysis", "Home Dialysis"],
//     hasViralPositive: true,
//     viralPositiveList: ["Hepatitis B", "C", "HIV"],
//     dialysor: "Done",
//     price: { "general": 7500, "viral-positive": 8500 },
//     hasEmergencyService: true,
//     category: "cardiology",
//     href: "/centers/egmore",
//     timings: "Mon-Sat : 07:30 AM - 08:00 PM",
//     patientSatisfactionScore: "93"
//   },
// ];

export default function Doctors() {
  pageTitle("Doctors");
  return (
    <>
      {/* <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.jpg"
        imgUrl="/images/doctors/banner_img.png"
        title="Our Centers"
        subTitle="The list of our centers all of India"
        customClasses="text-white"
      /> */}
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={30}>
        <Searchcenter/>
        {/* <TeamSectionStyle5 data={teamData} /> */}
      </Section>
      {/* <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={30}>
        <TeamSectionStyle5 data={teamData} />
      </Section> */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section> */}
    </>
  );
}
