import React from "react";
import { useLocation } from "react-router-dom";
import TeamSectionStyle5 from "../Section/TeamSection/TeamSectionStyle5";
import Section from "../Section";
import "../../sass/shortcode/findcenter.scss";
import { pageTitle } from "../../helpers/PageTitle";
import KidneyBreadcrumStyle from "../Breadcrumb/KidneyBreadcrumStyle";

const teamData = [
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis Center",
      designation: "New Delhi, Delhi",
      address: "A5 / 51, Ground floor, near, VIP store Gully, near Metro pillar 311, Block 1, New Delhi, Delhi, 110015i",
      contact: "+91 9111606162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      googleLink: "https://maps.app.goo.gl/SjTDE2sQjDsniUMj6",
      timings: "Mon-Sat : 08:00 AM - 08:00 PM",
      patientSatisfactionScore: "94",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis CenterA",
      designation: "Egmore, Chennai, Tamil Nadu.",
      address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
      contact: "+91 9116 06162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      timings: "Mon-Sat : 08:00 AM - 09:00 PM",
      patientSatisfactionScore: "97",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis CenterB",
      designation: "Egmore, Chennai, Tamil Nadu.",
      address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
      contact: "+91 9116 06162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      timings: "Mon-Sat : 08:30 AM - 09:00 PM",
      patientSatisfactionScore: "95",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis CenterC",
      designation: "Egmore, Chennai, Tamil Nadu.",
      address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
      contact: "+91 9116 06162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      timings: "Mon-Sat : 09:00 AM - 09:30 PM",
      patientSatisfactionScore: "99",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis CenterD",
      designation: "Egmore, Chennai, Tamil Nadu.",
      address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
      contact: "+91 9116 06162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      timings: "Mon-Sat : 08:00 AM - 08:00 PM",
      patientSatisfactionScore: "97",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
    {
      imgUrl: "/images/doctors/doctor_1.jpeg",
      department: "Directions",
      name: "KidneyNeeds Dialysis CenterE",
      designation: "Egmore, Chennai, Tamil Nadu.",
      address: "Kidney Needs Dialysis Center, Egmore Main Road, Chennai",
      contact: "+91 9116 06162",
      email: "support@kidneyneeds.com",
      services: ["Hemodialysis", "Home Dialysis"],
      hasViralPositive: true,
      viralPositiveList: ["Hepatitis B", "C", "HIV"],
      dialysor: "Done",
      price: { "general": 7500, "viral-positive": 8500 },
      hasEmergencyService: true,
      category: "cardiology",
      href: "/centers/egmore",
      timings: "Mon-Sat : 07:30 AM - 08:00 PM",
      patientSatisfactionScore: "93",
      mapsrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d869.2743127212067!2d79.54647656969654!3d29.367441567217426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0980fb82d0369%3A0xd5b0558224f76883!2sKutani%2C%20Mahargaon%2C%20Uttarakhand%20263136%2C%20India!5e0!3m2!1sen!2ssg!4v1723985311099!5m2!1sen!2ssg"
    },
  ];

export default function Findcenterresult() {
  pageTitle("Search Results");

  const { state: searchQuery } = useLocation(); // Extract searchQuery from state

  const normalize = (str) => str.trim().toLowerCase();

  // Filter results based on search query
  const filteredData = teamData.filter((center) => {
    const matchesHospital = normalize(center.name).includes(normalize(searchQuery?.hospital || ""));
    const matchesCity = normalize(center.designation).includes(normalize(searchQuery?.city || ""));
    const matchesPostal = normalize(center.address).includes(normalize(searchQuery?.postal || ""));
    return matchesHospital || matchesCity || matchesPostal;
  });

  // Sort results: prioritize exact matches
  const sortedData = filteredData.sort((a, b) => {
    const query = normalize(searchQuery?.hospital || "");
    const isExactMatchA = normalize(a.name) === query ? -1 : 0;
    const isExactMatchB = normalize(b.name) === query ? -1 : 0;

    return isExactMatchA - isExactMatchB; // Exact matches come first
  });

  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>

      <Section topMd={5} bottomMd={200} bottomLg={150} bottomXl={30}>
        {sortedData.length > 0 ? (
          <TeamSectionStyle5 data={sortedData} />
        ) : (
          <div className="no-results text-center">
            <h3>No results found</h3>
            <p>Please refine your search criteria.</p>
          </div>
        )}
      </Section>
    </>
  );
}
