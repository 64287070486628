import React from 'react';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
import DownloadBannerStyle from '../Section/BannerSection/DownloadBannerSectionStyle';
import Spacing from '../Spacing';
import DownloadBlogSection from '../Section/BlogSection/DownloadBlogSection';
import { downloaddata } from './downloaddata';
// import { Icon } from "@iconify/react";
// import Spacing from '../Spacing';


export default function Download() {
  pageTitle('Download');
  // const { t } = useTranslation(["common"]);
  // const { blogData } = commonConstants();

  const {
    DownloaddataList1,
    DownloaddataList2
  } = downloaddata();
  
  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* <Spacing lg="600" md="120" /> */}
      <Section className="cs_banner_styles">
        <DownloadBannerStyle
          bgUrl="/images/download/Download-Page-banner.jpg"
          subHeadTitle="Download"
          customeThemeClass=""
        />
      </Section>

       <Spacing lg="600" md="100" /> 
       {/* part 1 start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DownloadBlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={DownloaddataList1}
        />
      </Section>

{/* part 1 end */}

{/* part 2 start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DownloadBlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={DownloaddataList2}
        />
      </Section>
    </>
  );
}