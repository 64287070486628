import i18n from "../../i18n/i18n";

export const downloaddata = () => {
    const DownloaddataList1 = [
        {
            title: i18n.t("DownloadDataList1.DataList1.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList1.DataList1.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList1.DataList1.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList1.DataList1.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList1.DataList2.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList1.DataList2.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList1.DataList2.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList1.DataList2.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList1.DataList3.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList1.DataList3.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList1.DataList3.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList1.DataList3.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList1.DataList4.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList1.DataList4.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList1.DataList4.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList1.DataList4.dowbtnText",{
                ns: "download",
            }),
        }
    ];
    const DownloaddataList2 = [
        {
            title: i18n.t("DownloadDataList2.DataList1.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList2.DataList1.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList2.DataList1.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList2.DataList1.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList2.DataList2.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList2.DataList2.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList2.DataList2.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList2.DataList2.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList2.DataList3.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList2.DataList3.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList2.DataList3.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList2.DataList3.dowbtnText",{
                ns: "download",
            }),
        },
        {
            title: i18n.t("DownloadDataList2.DataList4.title",{
                ns: "download",
            }),
            subTitle: i18n.t("DownloadDataList2.DataList4.subTitle",{
                ns: "download",
            }),
            btnText: i18n.t("DownloadDataList2.DataList4.btnText",{
                ns: "download",
            }),
            dowbtnText: i18n.t("DownloadDataList2.DataList4.dowbtnText",{
                ns: "download",
            }),
        }
    ];

    return {
        DownloaddataList1,
        DownloaddataList2
      };
}
