import React, { useState } from "react";
import AboutSection from "../Section/AboutSection";
import Section from "../Section";
import BlogSection from "../Section/BlogSection";
import FaqSection from "../Section/FaqSection";
import { pageTitle } from "../../helpers/PageTitle";
import AppointmentWithContactInfoSection from "../Section/AppointmentWithContactInfoSection";
import BlogSectionStyle5 from "../Section/BlogSection/BlogSectionStyle5";
import DepartmentSectionStyle7 from "../Section/DepartmentSection/DepartmentSectionStyle7";
import HeroStyle2 from "../Hero/HeroStyle2";
import FunFactSection from "../Section/FunFactSection";
import GallerySection from "../Section/GallerySection";
import DepartmentSection from "../Section/DepartmentSection";
import ContactUsSlider from "../Slider/ContactUsSlider";
import PopupPageModalComponent from "../PopupPageModal";
import { homeBlogDatas } from "../../constants/common-contents";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { homedata } from "./Homedata";
import IntroVideo from "../../images/IntroVideo/kidneyneeds intro animation_2025.mp4"


export default function Home() {
  pageTitle("Home");

  const { t } = useTranslation(["homedata"]);

  const {
    DepartmentData,
    OurDepartmentData,
    GalleryData,
    ContactUsSliderData,
    FunFactData,
    BlogData,
    WorldKidneyDayDataList1,
    WorldKidneyDayDataList2,
    InfographicIdeasDatalist,
    FaqData
  } = homedata();

  const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);

  const openGrantModal = () => setIsGrantModalOpen(true);
  const closeGrantModal = () => setIsGrantModalOpen(false);

  // const [isArtModalOpen, setArtIsModalOpen] = useState(false);

  // const openArtModal = () => setArtIsModalOpen(true);
  // const closeArtModal = () => setArtIsModalOpen(false);

  const [isInfoModalOpen, setInfoModalOpen] = useState(false);

  const openInfoModal = () => setInfoModalOpen(true);
  const closeInfoModal = () => setInfoModalOpen(false);

  return (
    <>
      {/* Banner Section Start */}
      <HeroStyle2
        title="KidneyNeeds"
        title2="Your Complete<br/> Kidney Health Solution"
        subTitle="Your trusted partner for comprehensive kidney care, providing convenient access to expert guidance, affordable medications, advanced diagnostics, and personalized, holistic support to empower your well-being."
        bgUrl="/images/home/home-page-1.jpg"
        imgUrl="images/home_2/patents-new.png"
        videoBtnText="See how we work"
        videoUrl={IntroVideo}
        btnText=""
        btnUrl="/"
        infoNavList={[
          {
            title: t("infoNavList.title1"),
            iconUrl: "/images/home/home-lab-test-icon.svg",
            href: "/",
          },
          {
            title: t("infoNavList.title2"),
            iconUrl: "/images/home/home-diet-chart-icon.svg",
            href: "/",
          },
          {
            title: t("infoNavList.title3"),
            iconUrl: "/images/home/home-dialysis-icon.svg",
            href: "/",
          },
          {
            title: t("infoNavList.title4"),
            iconUrl: "/images/home/home-medicine-icon1.svg",
            href: "/",
          },
        ]}
        funfactList={[]}
        customClasses="cs_home_page_banner"
      />
      {/* Banner Section End */}

      {/* Start Info Section */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_home_info_sec"
      >
        <div className="container">
          <div className="row g-4 g-xl-3 g-xxl-5">
            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
              onClick={openGrantModal}
            >
              <div className="cs_iconbox cs_style_11">
                <div className="cs_iconbox_icon ">
                <img className="rd_border"
                    loading="lazy"
                    src="/images/home/WKD-LogoRGB.jpg"
                    alt="Icon"
                    width={170}
                    height={170}
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">
                    World Kidney<br /> Day 2025
                  </h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    {t("GrantsFunding.GrantsFunding.para")}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
            // onClick={openArtModal}
            >
              <Link to="/ResearchConnectEnquiry">
                <div className="cs_iconbox cs_style_11">
                  <div className="cs_iconbox_icon">
                    <img className="rd_border"
                      src="/images/home/kn-home-small-images-2.jpg"
                      alt="Icon"
                    />
                  </div>
                  <div className="cs_iconbox_right">
                    <h3 className="cs_iconbox_title cs_fs_24 mb-0">
                      {t("GrantsFunding.KidneyResearch.title")}
                    </h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      {t("GrantsFunding.KidneyResearch.para")}
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
              onClick={openInfoModal}
            >
              <div className="cs_iconbox cs_style_11">
                <div className="cs_iconbox_icon">
                  <img
                    className="info-graphics rd_border"
                    src="/images/home/kn-home-small-images-3.jpeg"
                    alt="Icon"
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">
                    {t("GrantsFunding.InfographicIdeas.title")}
                  </h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                  {t("GrantsFunding.InfographicIdeas.para")}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </Section>
      {/* End Info Section */}

      {/* Start Blog Section */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={BlogData}
        />
      </Section>
      {/* End Blog Section */}

      {/* Department Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DepartmentSection
          sectionTitle={t("DepartmentSection.sectionTitle")}
          bgUrl="images/home/counter-BG.jpg"
          data={DepartmentData}
        />
      </Section>
      {/* Department Section End */}

      {/* Our Department Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DepartmentSectionStyle7
          sectionTitle={t("OurDepartmentSection.sectionTitle")}
          sectionTitleUp={t("OurDepartmentSection.sectionTitleUp")}
          data={OurDepartmentData}
          sectionTitleUpBrandColor=""
        />
      </Section>
      {/* Our Department Section End */}

      {/* About Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <AboutSection
          imgUrl="/images/home/about-new.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title={t("AboutSection.title")}
          subTitle={t("AboutSection.subTitle")}
          featureList={[
            {
              featureListTitle:
                t("AboutSection.featureList.featureListTitle"),
              featureListSubTitle:
                t("AboutSection.featureList.featureListSubTitle"),
            },
          ]}
        />
      </Section>
      {/* About Section End */}

      {/* Start Our Clients Section */}
      {/* <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <TestimonialSectionStyle4
          sectionTitle="Discover the Stories of Health and Healing"
          sectionTitleUp="WHAT OUR PATIENTS SAY"
          avatarListTitle="150+ patients are reviews about KidneyNeeds"
          avatarList={[
            { imgUrl: "/images/home_4/avatar_1_1.png" },
            { imgUrl: "/images/home_4/avatar_2_2.png" },
            { imgUrl: "/images/home_4/avatar_3_3.png" },
            { imgUrl: "/images/home_4/avatar_4_4.png" },
            { imgUrl: "/images/home_4/avatar_5_5.png" },
          ]}
          data={testimonialData}
        />
      </Section> */}
      {/* End Our Clients Section */}

      {/* Our Facilities Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <GallerySection
          sectionTitle={t("OurFacilities.sectionTitle")}
          sectionTitleUp={t("OurFacilities.sectionTitleUp")}
          data={GalleryData}
        />
      </Section>
      {/* Our Facilities End */}

      {/* Start Banner Section */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_home_info_sec"
      >
        {/* <Banner
          bgUrl="images/home/missed-call-BG.png"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let You & Your Loved Ones Healthy Life At Risk!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
          subPara="Give us missed call +91 91116 06162"
          fontStyle="cs_fs_72 cs_semibold"
        /> */}
        <div className="container">
          <div className="position-relative contact-us-slider">
            <ContactUsSlider data={ContactUsSliderData} />
          </div>
        </div>
      </Section>
      {/* End Banner Section */}

      {/* About Us Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <FunFactSection
          bgUrl="images/home/counter-BG.jpg"
          fontColor="cs_white_color"
          data={FunFactData}
        />
      </Section>
      {/* About Us Section End */}

      {/* Start Brand Section */}
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section> */}
      {/* End Brand Section */}

      {/* Appointment And Upload Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <AppointmentWithContactInfoSection customClass="cs_upload_prescription" />
      </Section>
      {/* Appointment And Upload Section End */}

      {/* Blog Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSectionStyle5
          sectionTitle={t("BlogSection.sectionTitle")}
          sectionTitleUp={t("BlogSection.sectionTitleUp")}
          sectionTitleDown=""
          sectionSubTitle=""
          data={homeBlogDatas().slice(0, 3)}
        />
      </Section>
      {/* Blog Section End */}

      {/* Start FAQ Section */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <FaqSection
          data={FaqData}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      <PopupPageModalComponent
        featureHeading={"Are Your Kidneys OK?"}
        featureSubHeading={"Join us in our mission to prevent and detect kidney disease early!"}
        featureListData={WorldKidneyDayDataList1}
        featureImage="/images/home/grant-funding.jpg"
        featureSubHeading2={"How You Can Help"}
        featureListData2={WorldKidneyDayDataList2}
        featureImage2="/images/home/grant-funding.jpg"
        heading={" "}
        isOpen={isGrantModalOpen}
        onClose={closeGrantModal}
      />
      {/* <PopupPageModalComponent
        featureHeading={"Research Connect Enquiry"}
        featureListData={kcFeatureList}
        featureImage="/images/home/research_connect.jpg"
        heading={" "}
        isOpen={isArtModalOpen}
        onClose={closeArtModal}
      /> */}
      <PopupPageModalComponent
        heading={" "}
        featureHeading={"Infographic Ideas to Get You Started"}
        featureListData={InfographicIdeasDatalist}
        featureImage="/images/home/kn-home-small-images-3.jpeg"
        isOpen={isInfoModalOpen}
        onClose={closeInfoModal}
      />
    </>
  );
}
