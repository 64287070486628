import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../sass/shortcode/findcenter.scss";

export default function Searchcenter() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState({
    hospital: "",
    city: "",
    postal: "",
  });

  const hospitalSuggestions = [
    "KidneyNeeds Dialysis Center",
    "KidneyNeeds Dialysis CenterA",
    "KidneyNeeds Dialysis CenterB",
    "KidneyNeeds Dialysis CenterC",
    "KidneyNeeds Dialysis CenterE",
  ];
  const citySuggestions = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"];
  const postalSuggestions = ["10001", "90001", "60601", "77001", "85001"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchQuery((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const normalize = (str) => str.trim().toLowerCase();

    const isHospitalValid = hospitalSuggestions.some(
      (hospital) => normalize(hospital) === normalize(searchQuery.hospital)
    );
    const isCityValid = citySuggestions.some(
      (city) => normalize(city) === normalize(searchQuery.city)
    );
    const isPostalValid = postalSuggestions.some(
      (postal) => normalize(postal) === normalize(searchQuery.postal)
    );

    if (isHospitalValid || isCityValid || isPostalValid) {
      navigate("/Searching-Results", { state: searchQuery });
    } else {
      alert("No matching results. Please enter valid data.");
    }
  };

  return (
    <div className="search-section">
     
        <div className="search-container">
        <form onSubmit={handleSearch}>
          <div className="container mt-3">
            <div className="row no-gutters">
              {/* Hospital Input */}
              <div className="col-md-4">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span>
                  <input
                    type="text"
                    name="hospital"
                    className="form-control"
                    list="hospital-options"
                    value={searchQuery.hospital}
                    onChange={handleInputChange}
                    // placeholder="Search Hospital (e.g., Kidney Hospital)"
                  />
                  <datalist id="hospital-options">
                    {hospitalSuggestions.map((hospital, index) => (
                      <option key={index} value={hospital} />
                    ))}
                  </datalist>
                </div>
              </div>

              {/* City Input */}
              <div className="col-md-4">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  list="city-options"
                  value={searchQuery.city}
                  onChange={handleInputChange}
                  // placeholder="City (e.g., New York)"
                />
                <datalist id="city-options">
                  {citySuggestions.map((city, index) => (
                    <option key={index} value={city} />
                  ))}
                </datalist>
              </div>

              {/* Postal Code Input */}
              <div className="col-md-4">
                <input
                  type="text"
                  name="postal"
                  className="form-control"
                  list="postal-options"
                  value={searchQuery.postal}
                  onChange={handleInputChange}
                  // placeholder="Postal Code (e.g., 10001)"
                />
                <datalist id="postal-options">
                  {postalSuggestions.map((postal, index) => (
                    <option key={index} value={postal} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="mt-3 text-center non">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
          </form>
        </div>
     
    </div>
  );
}
