import i18n from "../../i18n/i18n";

export const faqdatas = () => {
 
const Faqcarddatas = [
    {
      id: 1,
      category: 'Kidney Education',
      image: '/images/Faq/Kidney-Education-500x500-01.jpg',
      title: i18n.t("FaqcardData.card1.title", {
        ns: "faq",
      }),
      content: i18n.t("FaqcardData.card1.content", {
        ns: "faq",
      }),
    },
    {
        id: 2,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-02.jpg',
        title: i18n.t("FaqcardData.card2.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card2.content", {
          ns: "faq",
        }),
    },
    {
        id: 3,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-03.jpg',
        title: i18n.t("FaqcardData.card3.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card3.content", {
          ns: "faq",
        }),
    },
    {
        id: 4,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-04.jpg',
        title: i18n.t("FaqcardData.card4.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card4.content", {
          ns: "faq",
        }),
    },
    {
        id: 5,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-05.jpg',
        title: i18n.t("FaqcardData.card5.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card5.content", {
          ns: "faq",
        }),
    },
    {
        id: 6,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-06.jpg',
        title: i18n.t("FaqcardData.card6.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card6.content", {
          ns: "faq",
        }),
    },
    {
        id: 7,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-07.jpg',
        title: i18n.t("FaqcardData.card7.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card7.content", {
          ns: "faq",
        }),
    },
    {
        id: 8,
        category: 'Kidney Education',
        image: '/images/Faq/Kidney-Education-500x500-08.jpg',
        title: i18n.t("FaqcardData.card8.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card8.content", {
          ns: "faq",
        }),
      },


     
      {
        id: 11,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-01.jpg',
        title: i18n.t("FaqcardData.card11.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card11.content", {
          ns: "faq",
        }),
      },
      {
        id: 12,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-02.jpg',
        title: i18n.t("FaqcardData.card12.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card12.content", {
          ns: "faq",
        }),
      },
     
      {
        id: 13,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-03.jpg',
        title: i18n.t("FaqcardData.card13.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card13.content", {
          ns: "faq",
        }),
      },
      {
        id: 14,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-04.jpg',
        title: i18n.t("FaqcardData.card14.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card14.content", {
          ns: "faq",
        }),
      },
      {
        id: 15,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-05.jpg',
        title: i18n.t("FaqcardData.card15.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card15.content", {
          ns: "faq",
        }),
      },
      {
        id: 16,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-06.jpg',
        title: i18n.t("FaqcardData.card16.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card16.content", {
          ns: "faq",
        }),
      },
      {
        id: 17,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-07.jpg',
        title: i18n.t("FaqcardData.card17.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card17.content", {
          ns: "faq",
        }),
      },
      {
        id: 18,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-08.jpg',
        title: i18n.t("FaqcardData.card18.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card18.content", {
          ns: "faq",
        }),
      },
      {
        id: 19,
        category: 'Our Services',
        image: '/images/Faq/Our-Services-500x500-09.jpg',
        title: i18n.t("FaqcardData.card19.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card19.content", {
          ns: "faq",
        }),
      },


      {
        id: 21,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-01.jpg',
        title: i18n.t("FaqcardData.card21.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card21.content", {
          ns: "faq",
        }),
      },
      {
        id: 22,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-02.jpg',
        title: i18n.t("FaqcardData.card22.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card22.content", {
          ns: "faq",
        }),
      },
      {
        id: 23,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-03.jpg',
        title: i18n.t("FaqcardData.card23.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card23.content", {
          ns: "faq",
        }),
      },
      {
        id: 24,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-04.jpg',
        title: i18n.t("FaqcardData.card24.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card24.content", {
          ns: "faq",
        }),
      },
      {
        id: 25,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-05.jpg',
        title: i18n.t("FaqcardData.card25.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card25.content", {
          ns: "faq",
        }),
      },
      {
        id: 26,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-06.jpg',
        title: i18n.t("FaqcardData.card26.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card26.content", {
          ns: "faq",
        }),
      },
      {
        id: 27,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-07.jpg',
        title: i18n.t("FaqcardData.card27.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card27.content", {
          ns: "faq",
        }),
      },
      {
        id: 28,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-08.jpg',
        title: i18n.t("FaqcardData.card28.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card28.content", {
          ns: "faq",
        }),
      },
      {
        id: 29,
        category: 'Diet',
        image: '/images/Faq/Diet-Services-500x500-09.jpg',
        title: i18n.t("FaqcardData.card29.title", {
          ns: "faq",
        }),
        content: i18n.t("FaqcardData.card29.content", {
          ns: "faq",
        }),
      },
  ];



  return {
    Faqcarddatas,
   
    
  };
};
