// import { Icon } from '@iconify/react';
// import React from 'react';
// import { Link } from 'react-router-dom';


// export default function TeamStyle5({
//   imgUrl,
//   department,
//   name,
//   designation,
//   description,
//   social,
//   href,
//   address,
//   contact,
//   email,
//   services,
//   hasViralPositive,
//   viralPositiveList,
//   dialysor,
//   price,
//   hasEmergencyService, googleLink
// }) {

//   const openMaps = () => {
//     window.open(googleLink, "_blank")
//   }

//   return (
//     <div className="cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden" style={{ background: 'white' }}>
//       <div className="cs_member_img">
//         <Link style={{ height: '90%' }} to={href} className="d-block">
//           <img style={{ height: '100%', objectFit: 'cover' }} loading="lazy" src={imgUrl} alt="Doctor" />
//         </Link>
//         <div className="cs_label cs_white_color cs_pink_bg" style={{ cursor: 'pointer' }} onClick={openMaps}>{department}</div>
//       </div>
//       <div className="cs_team_meta cs_white_bg">
//         <div>
//           <h3 className="cs_member_name cs_fs_32">
//             <Link to={href}>{name}</Link>
//           </h3>
//           <p className="cs_member_designation cs_heading_color cs_medium">
//             {designation}
//           </p>
//           <p className="cs_member_description">
//             <Icon icon="mdi:location" style={{ color: '#e00a0c' }}> </Icon> {address}
//           </p>
//           <p className="cs_member_description d-flex justify-content-between align-items-center" >
//             <span className='d-flex align-items-center'><Icon icon="ic:baseline-phone" style={{ color: '#e00a0c', marginRight: 8 }}> </Icon> {contact} </span>
//             <span className='d-flex align-items-center'><Icon icon="material-symbols:mail" style={{ color: '#e00a0c', marginRight: 8 }}> </Icon> {email} </span>
//           </p>
//           <table class="table">
//             <tbody>
//               <tr>
//                 <td>Services</td>
//                 <td> {services.join(" | ")}</td>
//               </tr>
//               <tr>
//                 <td>Viral-Positive Dialysis</td>
//                 <td>{hasViralPositive ? `Available (${viralPositiveList.join(", ")})` : "Not Available"}</td>
//               </tr>
//               <tr>
//                 <td>Dialyser Reuse</td>
//                 <td>{dialysor}</td>
//               </tr>
//               <tr>
//                 <td>Price per session</td>
//                 <td>{`${price.general} (General) | ${price['viral-positive']} (Viral-Posistive)`}</td>
//               </tr>
//             </tbody>
//           </table>
//           <p className="cs_member_description" style={{ color: '#e00a0c', fontWeight: '500' }}>
//             Emergency Services - {hasEmergencyService ? "Available" : "Not Available"}
//           </p>
//         </div>
//         <div>
//           <div className="cs_social_links">
//             {social?.map((item, index) => (
//               <Link to={item.href} key={index}>
//                 <Icon icon={item.icon} />
//               </Link>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import "../../sass/shortcode/findcenter.scss"

export default function TeamStyle5({
  imgUrl,
  department,
  name,
  designation,
  description,
  social,
  href,
  address,
  contact,
  email,
  services,
  hasViralPositive,
  viralPositiveList,
  dialysor,
  price,
  hasEmergencyService,
  googleLink,
  timings, // New prop for timings
  patientSatisfactionScore, // New prop for satisfaction score
  mapsrc
}) {
  const openMaps = () => {
    window.open(googleLink, '_blank');
  };

  return (
    <div className="container">
      <div className="row align-items-center border rd" style={{ background: 'white'}}>
        {/* First Column: Image and Department */}
        <div className="col-md-4 px-0" style={{ height: '100%' }}>
  <div
    className="cs_member_img d-flex flex-column justify-content-between"
    style={{ height: '550px', width: '100%' }}
  >
    <Link to={href} className="d-block" style={{ height: '100%' }}>
      <img
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          borderRadius: '30px 0px 0px 0px'
        }}
        loading="lazy"
        src={imgUrl}
        alt="Center"
      />
    </Link>
    <div
      className="cs_label cs_white_color cs_pink_bg text-center"
      style={{
        height: '50px', 
        lineHeight: '50px', 
        width: '100%',
        cursor: 'pointer',
        padding: '0',
        borderRadius: '0px 0px 0px 10px'
      }}
      onClick={openMaps}
    >
      {department}
    </div>
  </div>
</div>


        {/* Second Column: Main Details */}
        <div className="col-md-5 px-4">
          <h3 className="cs_member_name">
            <Link to={href} className="text-dark">
              {name}
            </Link>
          </h3>
          <p className="cs_member_designation text-muted">{designation}</p>
          <p  onClick={openMaps} className="clickable" >
            <Icon icon="mdi:location" style={{ color: '#e00a0c' }} /> {address}
          </p>
          <p className="d-flex align-items-center">
            <Icon icon="ic:baseline-phone" style={{ color: '#e00a0c', marginRight: 8 }} /> {contact}
          </p>
          <p className="d-flex align-items-center">
            <Icon icon="material-symbols:mail" style={{ color: '#e00a0c', marginRight: 8 }} /> {email}
          </p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Services</td>
                <td>{services.join(' | ')}</td>
              </tr>
              <tr>
                <td>Viral-Positive Dialysis</td>
                <td>{hasViralPositive ? `Available (${viralPositiveList.join(', ')})` : 'Not Available'}</td>
              </tr>
              <tr>
                <td>Dialyser Reuse</td>
                <td>{dialysor}</td>
              </tr>
              <tr>
                <td>Price per session</td>
                <td>{`${price.general} (General) | ${price['viral-positive']} (Viral-Positive)`}</td>
              </tr>
            </tbody>
          </table>
          <p style={{ color: '#e00a0c', fontWeight: '500' }}>
            Emergency Services - {hasEmergencyService ? 'Available' : 'Not Available'}
          </p>
        </div>

        {/* Third Column: Day and Timing */}
        <div className="col-md-3 text-center">

          <p className="mb-2">
            <Icon icon="mdi:calendar-clock" style={{ color: '#007bff', marginRight: 8 }} />
            {timings}
          </p>
          <p className="mb-2">
            <Icon icon="mdi:star" style={{ color: '#ffc107', marginRight: 8 }} />
            {patientSatisfactionScore}% Patient Satisfaction Score
          </p>

          <div className="cs_ma">
          <iframe
           id="map"
            title="Google Map"
           src={mapsrc}
           allowFullScreen
           style={{ width: "200px", height: "210px", borderRadius: "30px" }}
            />
           </div>
          {/* <button
            className="btn btn-danger"
            style={{ borderRadius: '20px', padding: '5px 20px' }}
            onClick={openMaps}
          >
            Direction
          </button> */}
        </div>
      </div>
    </div>
  );
}
