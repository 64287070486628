import React from 'react';
// import SideMenuWidget from '../Widget/SideMenuWidget';
import { blogDatas } from '../../constants/common-contents';
import { CustomToastContainer } from "../../helpers/toastHelper";
import RecentAnnouncement from '../Widget/Recentannouncement';


// const categoryData = [
//   {
//     title: 'Health Tips and Tricks',
//     url: '/',
//   },
//   {
//     title: 'Trends and Analysis',
//     url: '/',
//   },
//   {
//     title: 'Time Management',
//     url: '/',
//   },
// ];

export default function Newssidebar() {
  return (
    <div className="cs_sidebar1">
      {/* <div className="cs_sidebar_item widget_categories">
        <SideMenuWidget title="Popular Categories" data={categoryData} />
      </div> */}
      <CustomToastContainer />
      <div>
        <RecentAnnouncement title="LunaKIDNEY" data={blogDatas().slice(0, 5)} />
      </div>
      
    </div>
  );
}
