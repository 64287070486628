import React from 'react';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
import FaqBannerSectionStyle from '../Section/BannerSection/FaqBannerSectionStyle';
import "../../sass/shortcode/faq.scss"
import Spacing from '../Spacing';
// import SectionHeading from '../SectionHeading';
import { NewsandAnnouncements } from './NewsAndAnnnouncements';
import AnnouncementsImageWithOverlay from '../ImageWithTextOverlay/AnnouncementImageSection';
// import Spacing from '../Spacing';
// import { useTranslation } from 'react-i18next';
// import { faqdatas } from './Faq1';



export default function NewsAnnouncements() {
  pageTitle('News and Announcements');
  //   const { t } = useTranslation(["faq"]);
  // const { blogData } = commonConstants();

  const {
    blogInfoGrapics

  } = NewsandAnnouncements();


  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>

      <Section className="cs_banner_styles">
        <FaqBannerSectionStyle
          bgUrl="/images/news_Announcement/News-and-announcement-banner.jpg"
          subHeadTitle={
            <>
              News <br />
              and <br />
              Announcements
            </>
          }
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}



      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling_img_txt_sec"
      >
        {/* <SectionHeading
          title="infographicIdeas.title"
          subTitle="infographicIdeas.description"
          center
        /> */}
        <Spacing md="80" lg="80" />
        <AnnouncementsImageWithOverlay
          showTint={false}
          imageWithTextData={blogInfoGrapics}
        />
      </Section>


    </>
  );
}
