import React, { useEffect, useRef, useState } from 'react';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
import "../../sass/shortcode/faq.scss";
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import Spacing from '../Spacing';
import Newssidebar from '../Sidebar/Newsidebar';
import "../../sass/shortcode/announcement.scss"
import FaqSection from '../Section/FaqSection';
// import StarRating from './starrating';
import { NewsandAnnouncementsdata } from './AnnouncementPagedata';
import DepartmentSection from '../Section/DepartmentSection';
import { useTranslation } from 'react-i18next';
import BannerSectionStyle12 from '../Section/BannerSection/BannerSectionStyle12';
import FeaturesSectionStyle6 from '../Section/FeaturesSection/FeaturesSectionStyle6';
import Lunakidneybye from './lunabyenow';

export default function Newsannouncements1() {
  pageTitle('LunaKIDNEY Capsule');
  const { t } = useTranslation(["announcement"]);
  // const { blogData } = commonConstants();

  const {
    faqdatas

  } = NewsandAnnouncementsdata();

  const cathetersSpecializedData = [
    {
      title: "Counselling",
      iconUrl: "/images/home/counselling-1.png",
      href: "/our-services/counselling",
    },
    {
      title: "Health Records",
      iconUrl: "/images/home/Managing-Health-2.png",
      href: "/our-services/health-records",
    },
    {
      title: "Specialised Diet",
      iconUrl: "/images/home/Specialised-Diet-3.png",
      href: "/our-services/kidney-diet",
    },
    {
      title: "Blood Test Lab",
      iconUrl: "/images/home/Blood-Test-Lab-4.png",
      href: "/our-services/lab-test-service",
    },
    {
      title: "Buy Medicines With Us",
      iconUrl: "/images/home/Medicines-5.png",
      href: "/our-services/medicines",
    },
    {
      title: "Dialysis & Catheter",
      iconUrl: "/images/home/Dialyzers-Catheter-6.png",
      href: "/our-services/catheters",
    },
  ];


  const sectionRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const sectionBottom = sectionRef.current.getBoundingClientRect().bottom;

      if (sectionTop <= 100 && sectionBottom > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      <div className="container">
        <Spacing lg="10" md="20" />
        <Section bottomMd={30} bottomLg={20} bottomXl={40}>
          <h2>{t("title")}</h2>
          <h3>{t("subtaitle")}</h3>
          {/* <Spacing lg="10" md="20" /> */}
          <img
            src="/images/news_Announcement/Luna-kidney-news-and-announcment_small_banner.jpg"
            alt="Blog Details"
            className="w-100 cs_radius_20"
          />
        </Section>

        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            {/* <div className="cs_blog_details_tags">
              
            </div> */}
            <div className="cs_blog_details_date">
              {t("socialmedia.left")}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>{t("socialmedia.right")}</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>

        <Spacing md="70" lg="20" />

        <div ref={sectionRef} className="Announcement_details">
          <div className="Aleft_container">
            <div className="message_from_ceo_container">
              <h3 style={{ margin: 3 }}>{t("Announcementslist1.title")}</h3>
              <h4>Introducing LunaKIDNEY: A Revolutionary Nutraceutical for Kidney Wellness</h4>
              <p >Ponoogun Healthcare, a leading name in nephrology innovation in India, is proud to announce the launch of LunaKIDNEY, a groundbreaking nutraceutical supplement designed to redefine kidney health management. Built on years of research, LunaKIDNEY combines Melatonin, Prebiotics, and Probiotics to address the multifaceted challenges faced by Chronic Kidney Disease (CKD) patients</p>
              <p>This holistic solution targets key systems—kidney, gut, cardiovascular, and circadian rhythm—to empower patients with improved health and quality of life. LunaKIDNEY isn’t just a supplement; it’s a comprehensive wellness tool for patients at all stages of CKD, including pre-dialysis and dialysis.</p>

              <h3 style={{ margin: 3 }}>Message from the CEO, Ponoogun Healthcare</h3>
              <h4>Introducing LunaKIDNEY: One Capsule, Total Wellness</h4>
              <p>
                At Ponoogun Healthcare, our vision is rooted in enhancing the quality of life for those affected by CKD in India. CKD is not just a medical condition; it profoundly impacts the daily lives of patients and their caregivers. From managing sleep disturbances to addressing gut health, inflammation, and cardiovascular risks, the journey is challenging. It demands a solution that goes beyond conventional treatments, one that supports holistic care and empowers patients to lead fuller lives.
              </p>
              <p>
                This inspired us to create LunaKIDNEY, a nutraceutical supplement designed to redefine kidney health management. We call it "One Capsule, Total Wellness" because it brings together scientifically validated ingredients—Melatonin, Prebiotics, and Probiotics—to address the multifaceted challenges faced by CKD patients, whether they are pre-dialysis, on dialysis, or seeking preventive care.
              </p>
              <h4>Holistic Care for All Stages of CKD</h4>
              <p>LunaKIDNEY targets the interconnected systems within the body: the Pineal-Gut-Cardiac-Renal axis. By improving sleep quality, balancing the gut microbiome, and reducing inflammation, it offers comprehensive support for kidney function. Whether you are a non-dialysis patient seeking to slow CKD progression or a dialysis patient managing systemic complications, LunaKIDNEY is designed with your unique needs in mind.</p>
            </div>
            <h3 style={{ margin: 0 }}>Key Features and Benefits of LunaKIDNEY</h3>
            <h4 style={{ margin: 0 }}>1.Renal Function Preservation</h4>
            <div style={{ margin: 0, padding: 0 }}>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Melatonin combats oxidative stress and inflammation to protect the kidneys from damage.</li>
                <li style={{ margin: 0, padding: 3 }}>Reduces fibrosis and slows CKD progression, preserving kidney function</li>
              </ul>
            </div>
            <Spacing md="10" />
            <h4 style={{ margin: 0 }}>2.	Gut Microbiome Modulation</h4>
            <div style={{ margin: 0, padding: 0 }}>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Restores gut flora with advanced probiotics like Lactobacillus casei, Bifidobacterium breve, and Streptococcus thermophilus.</li>
                <li style={{ margin: 0, padding: 3 }}>	Prebiotics like Inulin fuel the growth of beneficial gut bacteria, reducing inflammation and improving digestion.</li>
              </ul>
            </div>
            <Spacing md="10" />
            <h4 style={{ margin: 0 }}>3. Circadian Rhythm and Sleep Regulation</h4>
            <div style={{ margin: 0, padding: 0 }}>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Melatonin regulates the sleep-wake cycle, promoting restful sleep and reducing CKD-related fatigue.</li>
                <li style={{ margin: 0, padding: 3 }}>Better sleep enhances daily energy levels and overall well-being.</li>
              </ul>
            </div>
            <Spacing md="10" />
            <h4 style={{ margin: 0 }}>4.	Cardiovascular Protection</h4>
            <div style={{ margin: 0, padding: 0 }}>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Supports healthy blood pressure through improved circadian rhythm and targeted effects on the Renin-Angiotensin System (RAS).</li>
                <li style={{ margin: 0, padding: 3 }}>Reduces vascular aging and inflammation, addressing cardiovascular risks linked to CKD.</li>
              </ul>
            </div>
            <h4 style={{ margin: 0 }}>5.	Enhanced Quality of Life</h4>
            <div style={{ margin: 0, padding: 0 }}>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>By addressing kidney, gut, cardiovascular, and sleep health together, LunaKIDNEY empowers patients to reclaim vitality and well-being.</li>
              </ul>
            </div>
            <Spacing md="20" />
            {/* <Spacing md="25" /> */}
            <div className='quotes_container'>
              <div style={{ width: '74%', textAlign: 'center', color: "red" }}>
                <h4 className='textcolor'>"Kidney disease and post-transplant patients can live a joyful, productive and fulfilling life"</h4>
              </div>
            </div>

            <h3 style={{ margin: 0 }}>The Science Behind LunaKIDNEY</h3>
            <h4>LunaKIDNEY’s formulation is grounded in clinical research:</h4>
            <div>
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Melatonin Treatment in CKD (Cells, 2023): Highlights melatonin’s role in reducing oxidative stress and inflammation.</li>
                <li style={{ margin: 0, padding: 3 }}>Probiotics and Renal Function (Ren Fail, 2022): Demonstrates probiotics’ benefits in improving kidney function, glucose levels, and inflammation.</li>
                <li style={{ margin: 0, padding: 3 }}>Melatonin as an Antioxidant (J Pineal Res, 2016): Explores melatonin’s powerful antioxidant and anti-inflammatory effects.</li>
              </ul>
            </div>
            <Spacing md="20" />
            <h3 style={{ margin: 0 }}>Who Should Use LunaKIDNEY?</h3>
            <h4>LunaKIDNEY’s formulation is grounded in clinical research:</h4>
            <div >
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Patients at any stage of CKD, including pre-dialysis and dialysis.</li>
                <li style={{ margin: 0, padding: 3 }}>Individuals with poor sleep quality, fatigue, or circadian rhythm disturbances.</li>
                <li style={{ margin: 0, padding: 3 }}>Those managing gut dysbiosis or inflammation.</li>
                <li style={{ margin: 0, padding: 3 }}>Patients with hypertension, diabetes, or cardiovascular complications associated with CKD.</li>
              </ul>
            </div>
            <Spacing md="20" />
            <h3 style={{ margin: 0 }}>How Does LunaKIDNEY Work?</h3>
            <h4>LunaKIDNEY targets multiple systems to offer comprehensive wellness:</h4>
            <div >
              <ul style={{ margin: 0, paddingLeft: '40px', fontSize: '18px', lineHeight: '1.2', listStyleType: 'disc' }}>
                <li style={{ margin: 0, padding: 3 }}>Melatonin: Reduces oxidative damage, inflammation, and mitochondrial dysfunction while improving sleep.</li>
                <li style={{ margin: 0, padding: 3 }}>Probiotics: Restores gut health, reduces systemic toxins, and modulates immune responses.</li>
                <li style={{ margin: 0, padding: 3 }}>Prebiotics: Enhances gut flora, promoting better metabolic health and digestion.</li>
              </ul>
            </div>
            <Spacing md="25" />
            <h3 style={{ margin: 0 }}>About Ponoogun Healthcare</h3>
            <p>Ponoogun Healthcare is a trusted name in nephrology innovation in India. Our mission is to advance kidney health through innovation, integrity, and patient-centered care. With the launch of LunaKIDNEY, we are bringing cutting-edge, holistic solutions to CKD patients across the country.</p>
            <h3 style={{ margin: 0 }}>Disclaimer</h3>
            <p>This announcement is sponsored by Ponoogun Healthcare. For detailed product information or clinical inquiries, visit Ponoogun Healthcare’s official website  or consult your healthcare provider.</p>
            <Spacing md="25" />


          </div>
          <div className="Aright_container">
            <div className={`fixedd ${isFixed ? 'fixed' : ''}`}>
              <Newssidebar />
            </div>
            <Spacing md="50" />
          </div>
        </div>



        {/* Department Section Start */}
        <Section
          bottomMd={130}
          bottomLg={120}
          bottomXl={110}
          className="cs_service-catheters"
        >
          <DepartmentSection
            sectionTitle="We Are Specialized in"
            bgUrl="/images/our_services/catheters/specialized-sec-bg.jpg"
            data={cathetersSpecializedData}
          />
        </Section>
        {/* Department Section End */}

        <Section className="cs_footer_margin_0">
          <BannerSectionStyle12
            bgUrl="images/about/Bottom-Banner-ab-new.png"
            title="How to Buy LunaKIDNEY"
            subTitle={`LunaKIDNEY is now available with the convenience of home delivery! You can place your order by contacting <br/> <span class="highlighted-number">+91 91116 06162</span> through WhatsApp. A valid prescription from your healthcare provider is required for purchase.`}
            content="Get LunaKIDNEY delivered to your doorstep—ensuring you never miss this essential kidney health supplement."
            center
          />
        </Section>


        {/* <Spacing md="-30" /> */}
        <Section
          //    topMd={190}
          //    topLg={15}
          //    topXl={105}
          bottomMd={10}
          //  bottomLg={150}
          //  bottomXl={110}
          className="cs_white_bg cs_transplant"
        // sectionRef={organDonorRef}
        >
          <FeaturesSectionStyle6
            //   sectionTitle="Village and City Kidney Needs - Initiative"
            image1="images/news_Announcement/luna-kidney-2.jpg"
            image2="images/news_Announcement/luna-kidney-3.jpg"
          // data={whyBecomeAnOrganDonorData}
          />
        </Section>

        <Spacing md="50" />
        <div>
          <Lunakidneybye />
        </div>
        <Spacing md="50" />
        {/* FAQ Section Start */}
        <Section bottomMd={130} bottomLg={120} bottomXl={110}>
          <FaqSection
            data={faqdatas}
            sectionTitle="Frequently Asked Questions"
            sectionTitleUp="What People"
          />
        </Section>
        {/* FAQ Section End */}

      </div>
      {/* <Spacing md="200" xl="150" lg="110" /> */}


    </>
  );
}
