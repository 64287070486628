import React, { useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";


import { contactTemplate } from "../../constants/email-template/contact-template";
import { toastSuccess, toastFailure } from "../../helpers/toastHelper";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export default function ContactForm({ customStyle }) {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const fileRef = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const file = fileRef.current.files[0];
    // Create a new FormData object to send data as multipart/form-data
    if (
      !nameRef.current.value ||
      !messageRef.current.value ||
      !subjectRef.current.value
    ) {
      toastFailure("Please fill in all required fields!");
      return;
    }
    const data = new FormData();
    if (file) {
      const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > fileSizeLimit) {
        toastFailure("File size exceeds 2MB limit.");
        return;
      }

      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        toastFailure("Only JPEG, PNG, PDF and word files are allowed.");
        return;
      }
      data.append("file", file);
    }

    data.append(
      "body",
      contactTemplate({
        name: nameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
      })
    );
    data.append("subject", subjectRef.current.value);

    try {
      await axios.post(`${baseUrl}/contact-us`, data);
      toastSuccess("Message Sent!");
      resetForm();
    } catch (error) {
      toastFailure("Something went wrong. Please retry later.");
    }
  };

  const resetForm = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
    subjectRef.current.value = "";
  };

  const { t } = useTranslation(["contact"]);

  return (
    <div
      className={`cs_contact_form cs_style_1 cs_white_bg cs_radius_30 ${
        customStyle?.formContainer || ""
      }`}
    >
      <form onSubmit={handleSubmit} className="row cs_full_contact_form">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            {t("contacformtdata.name")}<sup>*</sup>
          </label>
          <input
            type="text"
            ref={nameRef}
            className="cs_form_field"
            placeholder="Amit Singh"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">{t("contacformtdata.email")}</label>
          <input
            type="email"
            className="cs_form_field"
            ref={emailRef}
            placeholder="example@gmail.com"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
          {t("contacformtdata.subject")}<sup>*</sup>
          </label>
          <input
            type="text"
            ref={subjectRef}
            className="cs_form_field"
            placeholder="Your enquiry is about"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
          {t("contacformtdata.Uploadprescription")}
          </label>
          <input
            type="file"
            ref={fileRef}
            className="cs_form_field"
            id="upload_prescription"
            name="upload_prescription"
          ></input>
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
          {t("contacformtdata.Message")}<sup>*</sup>
          </label>
          <textarea
            cols={30}
            rows={10}
            className="cs_form_field"
            placeholder="Tell us how we can help you..."
            ref={messageRef}
            defaultValue={""}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button type="submit" className="cs_btn cs_style_1">
            <span>{t("contacformtdata.Submit")}</span>
            <i>
              <img
                loading="lazy"
                src="/images/icons/arrow_white.svg"
                alt="Icon"
              />
              <img
                loading="lazy"
                src="/images/icons/arrow_white.svg"
                alt="Icon"
              />
            </i>
          </button>
        </div>
      </form>
    </div>
  );
}
