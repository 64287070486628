import i18n from "../../i18n/i18n";

export const aboutDatas = () => {
 
const OurOutherProductsdata = [
    {
      title: i18n.t("OurOutherProductsdata.product1.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata.product1.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-01ab.jpg",
    },
    {
      title: i18n.t("OurOutherProductsdata.product2.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata.product2.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-02ab.jpg",
    },
    {
        title: i18n.t("OurOutherProductsdata.product3.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata.product3.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-03ab.jpg",
      },{
        title: i18n.t("OurOutherProductsdata.product4.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata.product4.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-04ab.jpg",
      },
  ];

const OurteamData =[
    {
        imgUrl: 'images/about/Doctors320x230-01.jpg',
        name: i18n.t("OurteamData.Team1.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team1.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team1.description",{
            ns: "about",
        }),  
    },
    {
        imgUrl: 'images/about/Doctors320x230-02.jpg',
        name: i18n.t("OurteamData.Team2.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team2.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team2.description",{
            ns: "about",
        }),  
    },
    {
        imgUrl: 'images/about/Doctors320x230-03.jpg',
        name: i18n.t("OurteamData.Team3.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team3.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team3.description",{
            ns: "about",
        }),  
    }
];
const OurAwarddata =[
    {
        iconUrl: '/images/icons/award.svg',
        title: i18n.t("AwardSectiondata.Award1.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/award.svg',
        title: i18n.t("AwardSectiondata.Award2.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/award.svg',
        title: i18n.t("AwardSectiondata.Award3.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/award.svg',
        title: i18n.t("AwardSectiondata.Award4.title",{
            ns: "about",
        }),
    }
];
const OurOutherProductsdata2 = [
    {
      title: i18n.t("OurOutherProductsdata2.product1.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata2.product1.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-05ab.jpg",
    },
    {
      title: i18n.t("OurOutherProductsdata2.product2.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata2.product2.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-08ab.jpg",
    },
    {
        title: i18n.t("OurOutherProductsdata2.product3.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata2.product3.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-06ab.jpg",
      },{
        title: i18n.t("OurOutherProductsdata2.product4.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata2.product4.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-09ab.jpg",
      },
  ];



  return {
    OurOutherProductsdata,
    OurteamData,
    OurAwarddata,
    OurOutherProductsdata2
    
  };
};
