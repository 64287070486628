import i18n from "../../i18n/i18n";

export const homedata = () => {

    const DepartmentData = [
        {
            title: i18n.t("departmentData.department1.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/counselling-1.png",
            href: "/our-services/counselling",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department1.ctaText",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("departmentData.department2.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Managing-Health-2.png",
            href: "/our-services/health-records",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department2.ctaText",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("departmentData.department3.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Specialised-Diet-3.png",
            href: "/our-services/kidney-diet",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department3.ctaText",{
                ns: "homedata",
            })
        },{
            title: i18n.t("departmentData.department3.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Specialised-Diet-3.png",
            href: "/our-services/kidney-diet",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department3.ctaText",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("departmentData.department4.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Blood-Test-Lab-4.png",
            href: "/our-services/lab-test-service",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department4.ctaText",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("departmentData.department5.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Medicines-5.png",
            href: "/our-services/medicines",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department5.ctaText",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("departmentData.department6.title", {
                ns: "homedata",
              }),
            iconUrl: "/images/home/Dialyzers-Catheter-6.png",
            href: "/our-services/catheters",
            // bgUrl: "/images/home/department1.png",
            ctaText: i18n.t("departmentData.department6.ctaText",{
                ns: "homedata",
            })
        }
    ];

    const OurDepartmentData = [
        {
            title: i18n.t("ourDepartmentData.ourDepartment1.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment1.subTitle", {
                ns: "homedata",
              }),
            bgUrl: "/images/home/counselling-bg.jpg",
            href: "/our-services/counselling",    
        },
        {
            title: i18n.t("ourDepartmentData.ourDepartment2.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment2.subTitle", {
                ns: "homedata",
              }),
            bgUrl: "/images/home/medical-record-bg.jpg",
            href: "/our-services/health-records",   
        },
        {
            title: i18n.t("ourDepartmentData.ourDepartment3.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment3.subTitle", {
                ns: "homedata",
              }),
              bgUrl: "/images/home/catheters415x200.jpg",
              href: "/our-services/catheters",   
        },
        {
            title: i18n.t("ourDepartmentData.ourDepartment4.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment4.subTitle", {
                ns: "homedata",
              }),
            bgUrl: "/images/home/accessories415x200.jpg",
            href: "/our-services/dialysis-accessories",   
        },
        {
            title: i18n.t("ourDepartmentData.ourDepartment5.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment5.subTitle", {
                ns: "homedata",
              }),
            bgUrl: "/images/our_services/kidney-biopsy-gun/img-with-text-icon-01.jpg",
            href: "/our-services/kidney-biopsy-gun",    
        },
        {
            title: i18n.t("ourDepartmentData.ourDepartment6.title", {
                ns: "homedata",
              }),
            subTitle: i18n.t("ourDepartmentData.ourDepartment6.subTitle", {
                ns: "homedata",
              }),
            bgUrl: "/images/home/course.png",
            href: "/our-services/e-learning",  
        },
    ];

    const GalleryData =[
        {
            imgUrl: "/images/gallery/Gallery_Images1500x644-02.jpg",
            hoverText: i18n.t("galleryData.gallery1.hoverText", {
                ns: "homedata",
              }),
        },
        {
            imgUrl: "/images/home/latest-activities-2.jpg",
            hoverText: i18n.t("galleryData.gallery2.hoverText", {
                ns: "homedata",
              }),
        },
        {
            imgUrl: "/images/home/latest-activities-3.jpg",
            hoverText: i18n.t("galleryData.gallery3.hoverText", {
                ns: "homedata",
              }),
        },
        {
            imgUrl: "/images/home/latest-activities-4.jpg",
            hoverText: i18n.t("galleryData.gallery4.hoverText", {
                ns: "homedata",
              }),
        },
        {
            imgUrl: "/images/home/latest-activities-5.jpg",
            hoverText: i18n.t("galleryData.gallery5.hoverText", {
                ns: "homedata",
              }),
        }
    ];
    const ContactUsSliderData = [
        {
            bgUrl: "images/home/missed-call-BG.png",
            imgUrl: "images/home_1/Cta-Page-01.png",
            title: i18n.t("contactUsSliderData.contactUsSlider1.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("contactUsSliderData.contactUsSlider1.subTitle",{
                ns: "homedata",
            }),
            subPara: i18n.t("contactUsSliderData.contactUsSlider1.subPara",{
                ns: "homedata",
            }),
            fontStlye: "cs_fs_72 cs_semibold"
        },
        {
            bgUrl: "images/home/missed-call-BG.png",
            imgUrl: "images/home_1/Cta-Page-02.png",
            title: i18n.t("contactUsSliderData.contactUsSlider2.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("contactUsSliderData.contactUsSlider2.subTitle",{
                ns: "homedata",
            }),
            subPara: i18n.t("contactUsSliderData.contactUsSlider2.subPara",{
                ns: "homedata",
            }),
            fontStlye: "cs_fs_72 cs_semibold"
        },
        {
            bgUrl: "images/home/missed-call-BG.png",
            imgUrl: "images/home_1/Cta-Page-03.png",
            title: i18n.t("contactUsSliderData.contactUsSlider3.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("contactUsSliderData.contactUsSlider3.subTitle",{
                ns: "homedata",
            }),
            subPara: i18n.t("contactUsSliderData.contactUsSlider3.subPara",{
                ns: "homedata",
            }),
            fontStlye: "cs_fs_72 cs_semibold"
        },

    ];
    const FunFactData = [
        {
            number: i18n.t("funFactData.funFact1.number",{
                ns: "homedata",
            }),
            title: i18n.t("funFactData.funFact1.title",{
                ns: "homedata",
            }),
        },
        {
            number: i18n.t("funFactData.funFact2.number",{
                ns: "homedata",
            }),
            title: i18n.t("funFactData.funFact2.title",{
                ns: "homedata",
            }),
        },
        {
            number: i18n.t("funFactData.funFact3.number",{
                ns: "homedata",
            }),
            title: i18n.t("funFactData.funFact3.title",{
                ns: "homedata",
            }),
        },
        {
            number: i18n.t("funFactData.funFact4.number",{
                ns: "homedata",
            }),
            title: i18n.t("funFactData.funFact4.title",{
                ns: "homedata",
            }),
        },
        {
            number: i18n.t("funFactData.funFact5.number",{
                ns: "homedata",
            }),
            title: i18n.t("funFactData.funFact5.title",{
                ns: "homedata",
            }),
        },
    ];
    const BlogData = [
        {
            title: i18n.t("blogData.blog1.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("blogData.blog1.subTitle",{
                ns: "homedata",
            }),
            btnText: i18n.t("blogData.blog1.btnText",{
                ns: "homedata",
            }),
            thumbUrl: "images/home/Knowledge-Hub.jpg",
            href: "/kidney-education/kidney-diseases",
            socialShare: false,
        },
        {
            title: i18n.t("blogData.blog2.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("blogData.blog2.subTitle",{
                ns: "homedata",
            }),
            btnText: i18n.t("blogData.blog2.btnText",{
                ns: "homedata",
            }),
            thumbUrl: "images/home/know-about-us.jpg",
            href: "/our-services/counselling",
            socialShare: false,
        },
        {
            title: i18n.t("blogData.blog3.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("blogData.blog3.subTitle",{
                ns: "homedata",
            }),
            btnText: i18n.t("blogData.blog3.btnText",{
                ns: "homedata",
            }),
            thumbUrl: "images/home/our-services.jpg",
            href: "/our-services/counselling",
            socialShare: false,
        },
        {
            title: i18n.t("blogData.blog4.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("blogData.blog4.subTitle",{
                ns: "homedata",
            }),
            btnText: i18n.t("blogData.blog4.btnText",{
                ns: "homedata",
            }),
            thumbUrl: "images/home/are-you-at-risk.jpg",
            href: "/contact",
            socialShare: false,
        }
    ];
    const WorldKidneyDayDataList1 = [
        {
            title: i18n.t("WorldKidneyDayDataList1.DataList1.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList1.DataList1.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("WorldKidneyDayDataList1.DataList2.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList1.DataList2.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("WorldKidneyDayDataList1.DataList3.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList1.DataList3.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("WorldKidneyDayDataList1.DataList4.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList1.DataList4.subTitle",{
                ns: "homedata",
            })
        }
    ];
    const WorldKidneyDayDataList2 = [
        {
            title: i18n.t("WorldKidneyDayDataList2.DataList1.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList2.DataList1.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("WorldKidneyDayDataList2.DataList2.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList2.DataList2.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("WorldKidneyDayDataList2.DataList3.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("WorldKidneyDayDataList2.DataList3.subTitle",{
                ns: "homedata",
            })
        }
    ];
    const InfographicIdeasDatalist = [
        {
            title: i18n.t("InfographicIdeasDatalist.DataList1.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("InfographicIdeasDatalist.DataList1.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("InfographicIdeasDatalist.DataList2.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("InfographicIdeasDatalist.DataList2.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("InfographicIdeasDatalist.DataList3.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("InfographicIdeasDatalist.DataList3.subTitle",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("InfographicIdeasDatalist.DataList4.title",{
                ns: "homedata",
            }),
            subTitle: i18n.t("InfographicIdeasDatalist.DataList4.subTitle",{
                ns: "homedata",
            })
        },
    ];
    const FaqData = [
        {
            title: i18n.t("faqData.faqData1.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData1.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData2.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData2.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData3.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData3.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData4.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData4.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData5.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData5.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData6.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData6.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData7.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData7.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData8.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData8.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData9.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData9.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData10.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData10.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData11.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData11.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData12.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData12.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData13.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData13.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData14.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData14.content",{
                ns: "homedata",
            })
        },
        {
            title: i18n.t("faqData.faqData15.title",{
                ns: "homedata",
            }),
            content: i18n.t("faqData.faqData15.content",{
                ns: "homedata",
            })
        },
    ]
 



  return {
    DepartmentData,
    OurDepartmentData,
    GalleryData,
    ContactUsSliderData,
    FunFactData,
    BlogData,
    WorldKidneyDayDataList1,
    WorldKidneyDayDataList2,
    InfographicIdeasDatalist,
    FaqData
  };
};
