import i18n from "../../i18n/i18n";


export const NewsandAnnouncementsdata = () => {

    const faqdatas = [
      {
        title: i18n.t("faqdatalist.list1.title", {
            ns: "announcement",
          }),
          content: i18n.t("faqdatalist.list1.content", {
            ns: "announcement",
          }),
      },
      {
        title: i18n.t("faqdatalist.list2.title", {
            ns: "announcement",
          }),
          content: i18n.t("faqdatalist.list2.content", {
            ns: "announcement",
          }),
      },
      {
        title: i18n.t("faqdatalist.list3.title", {
            ns: "announcement",
          }),
          content: i18n.t("faqdatalist.list3.content", {
            ns: "announcement",
          }),
      },
      {
        title: i18n.t("faqdatalist.list4.title", {
            ns: "announcement",
          }),
          content: i18n.t("faqdatalist.list4.content", {
            ns: "announcement",
          }),
      },
      {
        title: i18n.t("faqdatalist.list5.title", {
            ns: "announcement",
          }),
          content: i18n.t("faqdatalist.list5.content", {
            ns: "announcement",
          }),
      }
     
    ]
    return {
      faqdatas
    };
  };
  